import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactTimeout from 'react-timeout';
import { graphql } from 'gatsby';
import * as AppActions from '../actions/AppActions';
import PortfolioGrid from '../components/PortfolioGrid/PortfolioGrid.js';
import Layout from '../layouts';

const Index = ({
  data: {
    site,
    allMarkdownRemark: { edges: posts },
  },
  location,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.HeroSection();
      window.FirstLoad();
      window.LazyLoad();
      window.FullPage();
      window.MasonryPortfolio();
      window.HideShowHeader();
      window.FooterAppear();
      window.Sliders();
      window.Lightbox();
      window.AppearIteam();
      window.BackToTop();
      window.ContactForm();
      window.ShowMain();
      ReactGA.pageview(window.location.pathname + window.location.search);
      AppActions.changeColors(site.siteMetadata.index.colors);
    }, 200);
    return () => clearTimeout(timer);
  }, [site.siteMetadata.index.colors]);

  return (
    <Layout location={location}>
      <div id="main-content" className="boxed-portfolio">
        <PortfolioGrid posts={posts} />
      </div>
    </Layout>
  );
};

export default ReactTimeout(Index);

Index.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "project" } } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            category
            tile {
              variant
              down
              background
              light
              subtitle
            }
            tags
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
    site {
      siteMetadata {
        index {
          colors {
            highlight
            background
          }
        }
      }
    }
  }
`;
