import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Img from 'gatsby-image';
import cx from 'classnames';
import LinkPageTransition from '../LinkPageTransition';
import AppStore from '../../store/AppStore';
import * as AppActions from '../../actions/AppActions';

class PortfolioItem extends Component {
  constructor() {
    super();
    this.startPageTransition = this.startPageTransition.bind(this);
  }

  startPageTransition = () => {
    AppActions.startPageTransition();
  };

  render() {
    const {
      tileVariant,
      tags,
      path,
      tileDown,
      light,
      image,
      tileSubtitle,
      title,
    } = this.props;

    return (
      <div
        className={`item ${tileVariant || null} ${tags
          .join(' ')
          .toLowerCase()}`}
      >
        <div className="item-transform">
          <div className="item-rotate">
            <LinkPageTransition
              to={path}
              className={`ajax-link ${tileDown ? 'item-down' : ''}`}
              data-type="page-transition"
            >
              <div
                className={cx({
                  'item-content': true,
                  'light-content': light,
                })}
              >
                <Img
                  fluid={AppStore.config.images[image]}
                  className="item-image"
                />
                <div className="item-caption">
                  <div className="item-overlay">
                    <div className="item-sub-mask">
                      <div className="item-cat">{tileSubtitle}</div>
                      <div className="item-case">View Project</div>
                    </div>
                    <div className="item-title">{title}</div>
                    <div className="svg-circle">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="50px"
                        height="50px"
                        viewBox="0 0 80 80"
                        xmlSpace="preserve"
                      >
                        <circle
                          className="circle is-inner"
                          cx="40"
                          cy="40"
                          r="36"
                        />
                        <circle
                          className="circle is-outer"
                          cx="40"
                          cy="40"
                          r="36"
                        />
                      </svg>
                      <i className="fa fa-arrow-right" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>
            </LinkPageTransition>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioItem;

PortfolioItem.propTypes = {
  tileVariant: PropTypes.string,
  tags: PropTypes.array,
  path: PropTypes.string,
  tileDown: PropTypes.bool,
  light: PropTypes.bool,
  image: PropTypes.string,
  tileSubtitle: PropTypes.string,
  title: PropTypes.string,
};
