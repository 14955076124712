import PropTypes from 'prop-types';
import React, { Component } from 'react';
// import Link from '../components/Link';

import PortfolioItem from '../PortfolioItem/PortfolioItem.js';

export default class PortfolioGrid extends Component {
  componentDidMount() {
    window.MasonryPortfolio();
  }

  render() {
    const { posts } = this.props;

    return (
      <div id="portfolio-wrap" className="bricks">
        <div className="portfolio" id="portfolio" data-col="2">
          {posts
            .filter((post) => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => (
              <PortfolioItem
                category={post.frontmatter.category}
                tileVariant={post.frontmatter.tile.variant}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                path={post.frontmatter.path}
                image={post.frontmatter.tile.background}
                light={post.frontmatter.tile.light}
                tileDown={post.frontmatter.tile.down}
                tileSubtitle={post.frontmatter.tile.subtitle}
                key={post.id}
              />
            ))}
        </div>
      </div>
    );
  }
}

PortfolioGrid.propTypes = {
  posts: PropTypes.array,
};
